import { render, staticRenderFns } from "./PP.vue?vue&type=template&id=28bbf17d&"
import script from "./PP.vue?vue&type=script&lang=js&"
export * from "./PP.vue?vue&type=script&lang=js&"
import style0 from "./PP.vue?vue&type=style&index=0&id=28bbf17d&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports