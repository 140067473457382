<template>
  <div style="margin: 10px">
    <div class="row">
      <div class="col-12 mb-3">
        <div class="alert alert-secondary" role="alert">
          Riesgos que identifica la víctima y las acciones necesarias para su
          efectiva protección, ya sea por parte de las autoridades o de los
          sujetos involucrados en la denuncia
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="sexo" class="form-label">
            Selecciona una institución
          </label>
          <v-select
            :options="institucionesJusticia"
            id="sexo"
            v-model="$v.form.instituto.$model"
            aria-label="sexo"
            label="texto"
            multiple
          >
            <template #search="{ attributes, events }">
              <input
                class="vs__search"
                :required="!$v.form.instituto.$model"
                v-bind="attributes"
                v-on="events"
              />
            </template>
            <slot name="no-options">No se encontraron coincidencias.</slot>
          </v-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <Editor tipoMedidas="medidasProteccion"></Editor>
      </div>
    </div>
  </div>
</template>
<script>
import Editor from "../../Editor/Editor";
import { mapState, mapMutations } from "vuex";
import { required } from "vuelidate/lib/validators";


export default {
  name: "Riegos",
  components: {
    Editor,
  },
  data() {
    return {
      form: {
        instituto: [],
      },
    };
  },
  activated: function () {
    /* console.log("activate"); */
    if (
      this.form.instituto.length > 0 &&
      this.medidasProteccion != "<p></p>" &&
      this.medidasProteccion != "<h3></h3>" &&
      this.medidasProteccion != ""
    ) {
      console.log("activate can");
      this.$emit("can-continue", { value: true });
    } else {
      // console.log("activate cant");
      this.$emit("can-continue", { value: false });
    }
  },
  computed: {
    ...mapState("generals", ["institucionesJusticia"]),
    ...mapState("contenido", ["medidasProteccion"]),
  },
  methods: {
    ...mapMutations("contenido", ["setFormatoDatos"]),
  },
  validations: {
    form: {
      instituto: {
        required,
      },
    },
  },
  watch: {
    $v: {
      handler: function (val) {
        // console.log("es invalido cambio.... ", val.$invalid, this.medidasRiesgos);
        if (
          !val.$invalid &&
          this.medidasProteccion != "<p></p>" &&
          this.medidasProteccion != "<h3></h3>" &&
          this.medidasProteccion != ""
        ) {
          this.$emit("can-continue", { value: true });
          // console.log("can continue watch");
          this.setFormatoDatos({ instituto: this.form.instituto });
        } else {
          this.$emit("can-continue", { value: false });
        }
      },
      deep: true,
    },
    medidasProteccion(val) {
      console.log(val);
      if (
        this.form.instituto.length > 0 &&
        this.medidasProteccion != "<p></p>" &&
        this.medidasProteccion != "<h3></h3>" &&
        this.medidasProteccion != ""
      ) {
        this.$emit("can-continue", { value: true });
        this.setFormatoDatos({ instituto: this.form.instituto });
          // console.log("can continue medidasProteccion watch");
      }else {
        this.$emit("can-continue", { value: false });
      }
    },
    clickedNext(val) {
      if (val === true) {
        this.$v.form.$touch();
        // this.setFormatoDatos(this.form)
      }
    },
  },
};
</script>
<style scoped>
.alert-secondary {
  color: #e2e3e5;
  background-color: #1c1c38;
  border-color: #1c1c38;
}
.selectMargin {
  margin-bottom: 200px;
}

.error {
  border: 1px solid #dc3545 !important;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}
</style>