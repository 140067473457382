<template>
  <div class="homepage-3">
    <ScrollupSection />
    <div class="main overflow-hidden">
      <HeaderSection />
      <BreadcrumbSection :titulo="titulo" :descripcion="descripcion" />
      <section class="section service-area">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <horizontal-stepper
                :steps="demoSteps"
                @completed-step="completeStep"
                @active-step="isStepActive"
                @stepper-finished="alert"
                class="m-5"
                locale="es"
              >
              </horizontal-stepper>
            </div>
          </div>
        </div>
      </section>
      <FooterSection/>
      <ModalSearchSection />
      <ModalRegistro/>
      <ModalMenuSection />
      <ModalTerms/>
      <ModalCapcha :titulo="tituloFormato"  :nombre-archivo="tituloArchivo" :url="url" :tipo-formato="1"/>
    </div>
  </div>

</template>

<script>
import { analytics } from "@/firebase.js";
import { mapMutations } from "vuex";
import ScrollupSection from "../../Scrollup/Scrollup";
import HeaderSection from "../../Header/HeaderOne";
import BreadcrumbSection from "../../Breadcrumb/Services";
import FooterSection from "../../Footer/FooterOne";

import ModalSearchSection from "../../Modal/ModalSearch/ModalSearch";
import ModalRegistro from "../../Modal/ModalSearch/ModalRegistro";
import ModalMenuSection from "../../Modal/ModalMenu/ModalMenu";
import ModalTerms from '../../Modal/ModalTerms/ModalTerms';
import ModalCapcha from "@/components/Modal/ModalCaptcha/ModalCapcha";

import HorizontalStepper from "vue-stepper";
import "vue-select/dist/vue-select.css";
import Pruebas from "../../../components/forms/VG/Pruebas";
import GeneralFormRepresentate from "../../../components/forms/GeneralesRepresentante";
import Generals from "../../../components/forms/Generals";
import Complement from "../../../components/forms/VG/Complement";
import MotivosAgravio from "../../../components/forms/VG/MotivosAgravio";
import Riegos from "../../../components/forms/VG/Riesgos";

export default {
  name: "ViolenciaGenero",
  metaInfo: {
    title: "Electoral Tech & Legan | Violencia Política en razón de género ",
    description:
      "Crea y genera de manera simple tu formato de violencia política en razón de género",
  },
  components: {
    ScrollupSection,
    HeaderSection,
    BreadcrumbSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ModalRegistro,
    ModalTerms,
    HorizontalStepper,
    ModalCapcha
  },
  mounted() {
    analytics.logEvent("formatoComenzado", { name: "violenciaGenero" });
    window.$("#modalTerminos").modal("show");
    this.setTipoFormato(1);
    // window.$("#ModalCaptcha").modal("show");
  },
  data() {
    return {
      titulo: "Violencia política en razón de género",
      tituloFormato: "Violencia politica en razón de género",
      tituloArchivo: "formato-politica-en-razon-genero.pdf",
      descripcion:
        "¿Requieres la emisión de medida de medidas cautelares o de protección por parte del INE? Genera tu propia solicitud digital de una manera sencilla y rápida.",
      urlProd:
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/generarVPRG",
      urlLocal:
        "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/generarVPRG",
      demoSteps: [
        {
          icon: "person",
          name: "first",
          title: "Datos del representante",
          // subtitle: "Subtitle sample",
          component: GeneralFormRepresentate,
          completed: false,
        },
        {
          icon: "person",
          name: "second",
          title: "Datos del accionante",
          // subtitle: "Subtitle sample",
          component: Generals,
          completed: false,
        },
        {
          icon: "announcement",
          name: "third",
          title: "Actos denunciados",
          // subtitle: "Subtitle sample",
          component: Complement,
          completed: false,
        },
        {
          icon: "list",
          name: "fourth",
          title: "Motivos de agravio ",
          // subtitle: "Subtitle sample",
          component: MotivosAgravio,
          completed: false,
        },
        {
          icon: "speaker_notes",
          name: "fifth",
          title: "Pruebas",
          // subtitle: "Subtitle sample",
          component: Pruebas,
          completed: false,
        },
        {
          icon: "report_problem",
          name: "sixth",
          title: "Medidas Protección",
          // subtitle: "Subtitle sample",
          component: Riegos,
          completed: false,
        },
      ],
    };
  },
  computed: {
    url() {
      return process.env.NODE_ENV == "development"
        ? this.urlLocal
        : this.urlProd;
    },
  },
  methods: {
    ...mapMutations('generals', ['setTipoFormato']),
    completeStep(payload) {
      this.demoSteps.forEach((step) => {
        if (step.name === payload.name) {
          step.completed = true;
        }
      });
    },
    errorMessage(error) {
      this.$swal({
        icon: "error",
        title: error,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCancelButton: true,
      });
    },
    // Executed when @active-step event is triggered
    isStepActive(payload) {
      this.demoSteps.forEach((step) => {
        if (step.name === payload.name) {
          if (step.completed === true) {
            step.completed = false;
          }
        }
      });
    },
    alert() {
      window.$("#ModalCaptcha").modal("show");
    },
  },
};
</script>

<style>
.stepper-box .content {
  overflow: visible !important;
}
</style>